
body.template-startpage_view {
    #content {
      >header {
        background-color: $dgho-ja2025-color;
        color: white !important;
        padding: 30px 20px;
      }
  
      #content-core {
        background-color: transparent;
        margin: 0;
        padding: 0;
      }
  
      .epilog {
        background-color: white;
        padding: 15px;
  
        h1 {
          margin: 5px auto;
        }
      }
    }
  }
  
  
  /* --- startpage -- */
  
  .tiles-wrapper {
    margin: 10px 5px 5px 5px;
  
    .tile {
      margin-bottom: 0px;
      padding: 10px;
      overflow: hidden;
      float: left;
  
      .tile-img {
        /*background-color: #f0f0f0;*/
        overflow: hidden;
  
        img {
          width: 100%;
        }
      }
  
      .tile-vid {
        overflow: hidden;
        background-color: #ccc;
        height: 180px;
  
        video {
          width: 100%;
          height: 180px;
          object-fit: cover;
        }
      }
  
      .tile-text {
        height: 135px;
        overflow: hidden;
        background-color: #FFF !important;
        padding: 10px;
  
        h2 {
          font-size: 18px;
          color: $dgho-ja2025-color;
          text-transform: uppercase;
          margin: 10px 0;
        }
  
        a {
          color: $dgho-ja2025-color;
        }
  
        p {
          overflow: hidden;
        }
      }
  
      .tile-footer {
        text-align: right;
        background-color: #FFF;
        padding: 15px;
        text-align: right;
      }
    }
  }
  
  