

body.section-industrie {
    &[class*=subsection-indust-]  {
        #portal-globalnav_XXXXXX {
            li {
                background-color: green !important;
            }
            > li:nth-child(0) {
                background-color: gray !important;
            } 
            > li:nth-child(1) {
                background-color: gray !important;
            } 
            > li:nth-child(2) {
                background-color: gray !important;
            } 
            
            > li:nth-child(3) {
                background-color: gray !important;
            } 
            > li:nth-child(4) {
                background-color: gray !important;
            } 
        }
        #portal-globalnav {
            > li {
                > a:not([href*=\/industrie\/]) {
                    display: none;
                }
                &.has_subtree {
                    display: none;
                }
            }
        }
        #portal-logo-wrapper {
            margin: 10px 0 20px 0;
            background: url("/++theme++dgho-ja/img/logo/logo-industrie.png") center left no-repeat;
            background-size: contain;
            height: 230px;
            width: 100%;
            display: block;

            img {
                display: none;
            }
        }

        article > header {
            background-color: $dgho-dark-gray;
            color: white !important;
            padding: 30px 20px;
        }
        

    }

    /* --- TAB/PILL-Navigation in den Dokumenten */
    .tab-content-wrapper {
        margin: 0px auto;

        #tab-menu { 
            .nav-pills {
                text-transform: uppercase;
                border-bottom: 1px solid $dgho-ja2025-color;
                
                > li {
                    border: none;
                }
                > li > a {
                    color: #6e6f71;
                    border-radius: 0;
    
                    display: block;
                    padding: 10px 20px;
                    width: 20vw;
                    text-align: center;
                }
                > li.active > a,
                > li.active > a:focus,
                > li.active > a:hover {
                    color: white;
                    background-color: $dgho-ja2025-color;
                    text-transform: uppercase;
                    text-decoration: none !important;
                }
                > li > a:focus,
                > li > a:hover {
                    background-color: $dgho-ja2025-color;
                    text-decoration: none !important;
                    color: white;
                }
            }
        }
        #tab-content {
            margin-top: 20px;
            .tab-pane {
                h2 {
                    color: $dgho-ja2025-color;
                    text-transform: uppercase;
                    font-size: 22px;                    
                }
            }
            .tab-title {}
        }
            
    }
    /* --- TAB/PILL-Navigation in den Dokumenten */

}  

    
  