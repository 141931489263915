$primary: #e32119;
// $primary: #ccc;
$secondary: #87130f;
$tertiary: #1B470E;

$dgho-ja2025-color: #e32119;
$dgho-ja2025-bg-color: #ffffff;
$dgho-ja2025-gray: #6e6f71;

$dgho-dark-gray: #666666;


