
/* ??? ----------------------------------------------
  @media (min-width: 768px) {
    body {
        border-top: 0px solid #cef;
        }
    .navbar-nav {
      margin: 0 0 0 -15px;
    }
  }
  
  @media (max-width: 767px) {
    body {
        border-top: 10px solid #9ac;
        }
    .navbar-default {
      .navbar-nav {
        .open {
  
          .dropdown-menu>.active>a,
          .dropdown-menu>.active>a:focus,
          .dropdown-menu>.active>a:hover {
            color: #fff;
            background-image: none;
            filter: none;
            background-color: lighten($dgho-ja2025-color, 35%);
          }
  
          .dropdown-menu>li>a {
            color: darken($dgho-ja2025-gray, 30%);
          }
        }
      }
    }
  }
  -------------------------------- */


  /* --- logos --- */
  @media (max-width: 1200px) {
    body {
      border-top: 10px solid lawngreen;
    }
  
    #content-header {
      #portal-logo {
        margin: 0;
        background: url("/++theme++dgho-ja/img/logo/logo_header_orig.png") top left no-repeat;
        background-size: contain;
        // min-height: 310px;
        width: 100%;
        margin-top: -36px;
      }
    }
  }
  
  @media (max-width: 1140px) {
    body {
      border-top: 10px solid blue;
    }
  
    #content-header {
      #portal-logo {
        margin: 0;
        background: url("/++theme++dgho-ja/img/logo/logo_header_orig.png") top left no-repeat;
        background-size: contain;
        min-height: 240px;
        width: 100%;
        margin-top: -36px;
      }
    }
  }
  
  @media (max-width: 990px) {
    body {
      border-top: 10px solid yellow;
    }
    
    #content-header {
      #portal-logo {
        margin: 0 20px 0 auto;
        margin-top: 0px;
        background: url("/++theme++dgho-ja/img/logo/logo_header_900.png") top left no-repeat;
        background-size: auto;
        background-size: contain;
        min-height: 168px;
        width: 100%;
        margin-top: -32px;      
  
    }
    .navbar.navbar-expand-lg {
      position: absolute;
      width: 100px;
      right: 20px;
      text-align: right;
      float: right;
      display: block;
      top: 20px;
    }
  
  
      #anonymous-actions {
        margin: 5px 200px auto auto;
      }
    }
  }
  
  @media (max-width: 768px) {
    body {
      border-top: 10px solid green;
    }
  
    #content-header {
      #portal-logo {
        margin: 10px -30px 0 0;
        background: url("/++theme++dgho-ja/img/logo/logo_header_300_q.png") top center no-repeat;
        background-size: contain;
        min-height: 320px;
        width: 100%;
        // margin-top: -36px;
        img {
            display: none;
        }
      }
      .navbar.navbar-expand-lg {
        position: absolute;
        width: 100px;
        right: 20px;
        text-align: right;
        float: right;
        display: block;
        top: 20px;
      }
    
      #anonymous-actions {
        margin: 5px 160px 10px auto;
      }
    }
  }
  
  @media (max-width: 645px) {
    body {
      border-top: 10px solid orange;
    }
    .navbar.navbar-expand-lg {
      position: absolute;
      width: 100px;
      right: 20px;
      text-align: right;
      float: right;
      display: block;
      top: 20px;
    }
  
    #content-header {
      #portal-logo {
        margin: 10px -30px 0 0;
        background: url("/++theme++dgho-ja/img/logo/logo_header_300_q.png") top center no-repeat;
        background-size: contain;
        height: 320px;
        width: 100%;
        // margin-top: -36px;
        img {
            display: none;
        }
      }
    }
  }
  




  /* --- logos --- */
  @media (max-width: 576px) {
    body {
      border-top: 10px solid red;
    }
    .navbar.navbar-expand-lg {
      position: absolute;
      width: 100px;
      right: 20px;
      text-align: right;
      float: right;
      display: block;
      top: 20px;
    }
  
  
    //col-sm
    #deco {
      display: none;
    }
  
    .plone-breadcrumb {
      padding: 0 15px;
    }
  
    .img-responsive {
      width: 100%;
    }
  
    h1 {
      font-size: 20px;
    }
  
    h2 {
      font-size: 18px;
    }
  
    h3 {
      font-size: 16px;
    }
  
    .portletWrapper {
      margin: 0 0 20px 15px;
    }
  
    #deco {
      .teaser {
        padding: 30px;
        width: 100%;
        float: right;
        color: #313232;
        background: rgba(255, 255, 255, 0.7);
        height: 280px;
        margin: 0;
  
        p {
          font-size: 18px;
        }
      }
  
      .teaser-background {
        display: none;
      }
    }
  
    .triangle {
      background-image: none;
      background-color: transparent; //lighten($dgho-ja2025-color, 0%);
  
      >a {
        color: $dgho-ja2025-gray;
      }
    }
  
    .navbar-default .navbar-nav>li.open>a,
    .navbar-default .navbar-nav>li:hover>a {
      color: white;
      background-image: none;
      background-color: lighten($dgho-ja2025-color, 5%);
    }
  
    .navbar .navbar-nav .open .dropdown-menu>.active>a,
    .navbar .navbar-nav .open .dropdown-menu>.active>a:focus,
    .navbar .navbar-nav .open .dropdown-menu>.active>a:hover {
      color: #fff;
      background-image: none;
      filter: none;
      background-color: lighten($dgho-ja2025-color, 5%);
    }
  
    .navbar-default .navbar-nav>.open>a,
    .navbar-default .navbar-nav>.open>a:focus,
    .navbar-default .navbar-nav>.open>a:hover {
      color: white;
      background-color: lighten($dgho-ja2025-color, 5%);
    }
  
    .navbar-default .navbar-nav .active>a,
    .navbar-default .navbar-nav .active>a:focus,
    .navbar-default .navbar-nav .active>a:hover {
      color: white;
    }
  
    #content-header {
      #portal-logo {
        margin: 10px -30px 0 0;
        background: url("/++theme++dgho-ja/img/logo/logo_header_300_q.png") top center no-repeat;
        height: 320px;
        width: 100%;
        img {
            display: none;
        }
      }
  
      #search-button {}
  
      #portal-searchbox {
        background-color: lighten($dgho-ja2025-gray, 45%);
      }
  
      #anonymous-actions {
        float: right;
        margin: 10px 0 0 0;
      }
    }
  
    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
      border-color: #999;
    }
  }
  
  
  @media (max-width: 375px) {
    body {
      border-top: 10px solid rgb(255, 0, 255);
    }
    .navbar.navbar-expand-lg {
      position: absolute;
      width: 100px;
      right: 20px;
      text-align: right;
      float: right;
      display: block;
      top: 20px;
    }
  
    /* --- dgho-POPup --- */
    #content-header #anonymous-actions {
      float: left;
      margin: -400px 5px 0 0;
      ul {
        padding: 0;
      }
    }
  
    .portletWrapper {
      margin: 0 0 20px 15px;
    }
  
    .popup-wrapper {
      margin: 0 0 20px 15px;
  
      h1 {
        font-size: 24px !important;
        font-weight: bold;
      }
  
      h2>a.btn {
        font-size: 18px !important;
        font-weight: bold;
      }
  
      h3 {
        font-size: 20px !important;
      }
  
      position: fixed;
      top: 260px;
      width: 100%;
      z-index: 10000;
      display: none;
  
      #dgho-popup {
        width: 92%;
        min-height: 200px;
        /* 315 + 2 * 20px + title-height */
        ;
        margin: 0 auto;
        padding: 20px;
        display: none;
  
        .popup-footer {
          padding: 10px 0;
        }
      }
    }
  }
  