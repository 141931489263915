
#portal-searchbox {
    display: none;
}

article > header {
    background-color: $dgho-ja2025-color;
    color: white !important;
    padding: 30px 20px;
}

.portlet {
    &.card {
        border: none;   
        .card-header {
            background-color: none !important;
            color: $dgho-ja2025-color;
        }
    }
}

.btn {
    &.btn-dgho {
        background-color: $dgho-ja2025-color;
        color: white;
    }
}

h2, .h2 {
    font-size: 18px;
    color: $dgho-ja2025-color;
}

h4, .h4 {
    font-size: 18px;
    color: $dgho-ja2025-color;
}

a {
    text-decoration: none;
}

.error {
    color: red;
}
  
.portalMessage {
    padding: 15px;
    margin: 15px;
    color: white;
  
    &.error {
      background-color: lighten($dgho-ja2025-color, 10%);
    }
  
    &.info {
      background-color: lighten($dgho-ja2025-gray, 5%);
    }
}
  
  
#back-to-top {
    bottom: 20px;
    color: rgb(109, 173, 134);
    font-size: 50px;
    position: fixed;
    right: 20px;
    text-shadow: none;
    transition: all 0.2s ease 0s;
    z-index: 999999;
    display: none;
    cursor: pointer;
}
  
.fa {
    padding-right: 5px;
}
  
  
.accordion-title:hover {
    cursor: pointer;
}

.navbar-nav {
  .nav-item {
    .nav-link {
      line-height: 2.0rem;
    }
  }
}


.presentations {
    margin-bottom: 20px;
  
    .presentation {
        border-bottom: 30px solid #f2f2f2 !important;
        padding: 20px 0 20px 0;
    
        &:last-child {
            border-bottom: none;
        }
    
        .presentation-logo {
            .logo {
            max-width: 300px;
            margin: 5px 10px 20px 0;
            }
        }
  
        .presentation-abstract {
            .title {
                margin: 5px auto;
            }
  
        .referent {
            font-size: 15px;
            margin: 0 auto 20px 0;
            font-weight: bold;
        }
  
        .datetime {
            margin: 10px 0;
        }
      }
    }
}

  
#portal-footer-wrapper {
    background-color: darken($dgho-ja2025-gray, 15%);
    margin-top: 30px;
    text-align: left;
  
    .doormat {
      padding: 15px 0;
  
      p,
      span,
      div,
      address {
        color: white;
      }
  
      a {
        color: #FFF;
      }
  
      button {
        color: $dgho-ja2025-gray;
      }
  
      .headline {
        text-transform: uppercase;
        padding: 0.25em 0;
        font-size: 130%;
        margin-bottom: 0.25em;
        border-bottom: 1px dotted lighten($dgho-ja2025-gray, 35%);
  
        h2 {
          color: white;
          font-size: 20px;
        }
      }
    }
  }
  
  #colophon {
    padding: 5px 5px;
    font-size: 80%;
    text-align: right;
    color: #999;
  }

  
/* --- externe banner -- */
// kongress-fenster --- 

.banners {
    background-color: $dgho-ja2025-bg-color;
  
    .banner {
      float: left;
      margin: 20px 50px 20px 20px;
      border: 1px solid #ccc;
      box-shadow: 0 0 15px #CCC;
  
      &:hover {
        box-shadow: 0 0 15px yellow;
      }
  
      img {
        &.small_banner {
          width: 200px !important;
          height: 120px !important;
        }
  
        &.big_banner {
          width: 405px !important;
          height: 120px !important;
        }
      }
    }
  }
  
  
  